@import "variables.scss";

@mixin colorStyle($color) {
	background-color: $color;
	border: 1px solid black;
	width: 26px;
	height: 26px;
	border-radius: 50%;
}
@mixin shapeStyle($border) {
	width: 32px;
	height: 32px;
	border-radius: $border;
	border: 1px solid #707170;
	background-color: #f7f6f6;
	align-self: center;
}

.web-builder-header {
	display: flex;
	padding: 16px 24px;
	gap: 8px;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid;
	border-color: var(--border-color);
	.header-publish {
		padding: 8px 32px;
		font-weight: 700;
		background-color: var(--primary-color);
		color: white;
		border: none;
		border-radius: 8px;
	}
	.header-eye-icon {
		margin-inline-end: 4px;
	}
	.header-preview {
		padding: 8px 16px;
		background: linear-gradient(0deg, #eed9ba, #eed9ba),
			linear-gradient(0deg, #faf4eb, #faf4eb);
		border: 1px solid #eed9ba;
		border-radius: 8px;
		color: #6a4d1b;
	}
	.url-icon {
		padding: 9px 10px;
		border-radius: 6px;
		background: #FAF4EB;
	}
	.whatsApp-btn  {
		background-color: #F9FFFC;
		border: 1px solid #019D4C;
	 color: #019D4C;
	 font-size: 14px;
	 display: flex;
	 align-items: center;
	 justify-content: center;
		border-radius: 8px;
	  gap: 8px;
		&:focus {
			box-shadow: none;
			outline: 0;
  		}
		 svg path{
			 stroke: #019D4C;
		 }
	}
}
.web-builder-sidebar {
	position: sticky;
	top: 73px;
	height: calc(100vh - 73px);
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	border-inline-end: 1px solid;
	border-color: var(--border-color);
	.content-header {
		padding: 30px 28px;
		border-bottom: 1px solid;
		border-color: var(--border-color);
		width: 100%;
		h4 {
			font-size: 18px;
			font-weight: 700;
			line-height: 22px;
		}
	}
	.sidebar-nav {
		padding: 40px 16px;
		flex: 0 1 auto;
		display: flex;
		flex-direction: column;
		border-inline-end: 1px solid;
		border-color: var(--border-color);
		button {
			padding: 9px 10px;
			border-radius: 6px;
			margin-bottom: 10px;
			border: none;
			background: none;
			&.active {
				background: #faf4eb;
				path {
					stroke: var(--primary-color);
				}
			}
		}
	}
	.sidebar-content {
		flex: 1 auto;
		/////// content blocks //////
		.sidebar-content-blocks {
			overflow: scroll;
			height: 100%;
			.content-blocks {
				padding: 20px 14px;
				gap: 8px;
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				
				.image-container {
					padding: 24px 16px;
					border-radius: 4px;
					gap: 8px;
					background: linear-gradient(0deg, #f7f6f6, #f7f6f6),
						linear-gradient(0deg, #fbfbfa, #fbfbfa);
					border: 1px solid;
					border-color: var(--border-color);
				}
				img {
					max-width: 100%;
					height: auto;
				}
			}
			.content-forms {
				.form-title {
					padding: 20px 30px;
					color: #aeaeae;
				}
				.form-details {
					padding-inline: 30px;

					.form-element {
						padding-block: 8px;
						span {
							margin-inline-start: 8px;
						}
					}
				}
			}
		}
	}
	.sidebar-pages {
		.pages-details {
			padding: 20px 30px;

			.pages-element {
				padding-block: 8px;
				span {
					margin-inline-start: 8px;
				}
			}
		}
		.menu-nav-list {
			.nav-item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				margin-top: 0.5rem;
				padding: 9px 16px;
				border-radius: 6px;
				background-color: #F5F5F5;
				gap: 8px;
				.drag-icon {
					width: 24px;
					height: 24px;
					max-width: 24px;
				}
				.btn {
					background: transparent !important;
					border: 0 !important;
					transition: none !important;
				}
				.btn:disabled {
					background: transparent !important;
					opacity: 0.2;
					border: 0 !important;
					transition: none !important;
					cursor: not-allowed;
				}
			}
			.sub-nav-item {
				margin-inline-start: 1rem;
			}
			.delete-item {
				svg path {
					stroke: #707170;
				}
			}
		}
	}
	.sidebar-style {
		.style-color {
			padding: 16px 40px;
			.initial-colors {
				display: flex;
				gap: 16px;
				margin-bottom: 16px;
			}

			.red-color {
				@include colorStyle(rgba(var(--red-color)));
			}
			.yellow-color {
				@include colorStyle(rgba(var(--yellow-color)));
			}
			.green-color {
				@include colorStyle(rgba(var(--green-color)));
			}
			.blue-color {
				@include colorStyle(rgba(var(--blue-color)));
			}
			.purple-color {
				@include colorStyle(rgba(var(--purple-color)));
			}

			.color-input {
				display: flex;
				gap: 16px;
				input {
					width: 80px;
					border-color: lightgray;
					border-radius: 4px;
					padding-inline: 2px;
				}
			}
		}

		.style-font {
			padding: 16px 20px;
		}
		.style-shape {
			padding: 16px 20px;
			fieldset {
				display: flex;
				gap: 8px;
				input {
					align-self: flex-end;
				}

				.shape {
					@include shapeStyle(0px);
				}
				.rounded {
					@include shapeStyle(6px);
				}

				label {
					gap: 8px;
					padding: 8px 10px;
					border-radius: 8px;
					border: 1px solid;
					border-color: #dddcdc;
					display: flex;
					flex-direction: column;
					flex: 1;
					&:has(input[type="radio"]:checked) {
						border-color: var(--primary-color);
					}
				}
			}
		}
		.collapse-header {
			display: flex;
			justify-content: space-between;
			padding: 12px 16px;

			.collapse-name {
				display: flex;
				gap: 8px;
			}
			.toggle-collapse {
				background: none;
				border: none;
			}
		}
	}
	.sidebar-theme {
		.themes-container {
			padding: 20px 14px;
		}
		.theme-wrapper {
			position: relative;
			display: flex;
			flex-direction: column;
			gap: 4px;
			height: 280px;
			overflow: hidden;

			img {
				width: 100%;
				height: 75%;
				padding: 10px;
				object-fit: cover;
				object-position: top;
				border: 1px solid #DDDCDC;
				border-radius: 4px;
				// cursor: pointer;
			}
			&.active img {
				border: 3px solid #D4A655
			}
			.overlay {
				position: absolute;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				top: 3px;
				left: 3px;
				width: calc(100% - 6px);
				height: calc(75% - 6px);
				padding: 10px;
				border-radius: 4px;
				background-color: #00000066;
				opacity: 0;
				visibility: hidden;
				transition: opacity .35s visibility .35s ease-in-out;

				.btn {
					padding: 12px, 20px;
					border-radius: 4px;
					background-color: #D4A655;
					color: #fff;
					font-size: 16px;
				}

			}

			&:hover .overlay {
				opacity: 1;
				visibility: visible;
				transition: opacity .35s visibility .35s ease-in-out;
			}
		}
	}
}
.confirm-theme-modal {
	.modal-content {
		width: 90%;
		border: none;
		border-radius: 12px;
		box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
			0px 20px 24px -4px rgba(16, 24, 40, 0.08);
	}

	.modal-body {
		padding: 20px;
	
	}
 
	
	
	.header {
		color: #101828;
		font-size: 18px;
		font-weight: 600;
		line-height: 155.56%;
	}

	.icon {
		display: flex;
		width: 48px;
		height: 48px;
		padding: 4px;
		justify-content: center;
		align-items: center;
		border-radius: 28px;
		border: 8px solid #fef3f2;
		background-color: #fee4e2;
	}

	.info-text {
		color: #667085;
		font-size: 14px;
		font-weight: 400;
		line-height: 142.86%;
		[dir="rtl"] &{
			text-align: justify;
		}
		
	}
	.reject-btn,
	.confirm-btn {
		display: flex;
		padding: 10px 18px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		flex-grow: 1;
		border-radius: 8px;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
	}
	.reject-btn {
		border: 1px solid #d0d5dd;
		background-color: #fff;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		color: #344054;
	}
	.confirm-btn {
		border: 1px solid #d92d20;
		background: #ea5455;
		box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
		color: #fff;
	}
}
.web-builder-header-languages {
	display: flex;
	align-items: center;
	gap: 4rem;
	padding-top: 16px;
	font-weight: 500;
	font-size: 18px;
	color: #707170;
	border-bottom: 1px solid #EFEFEF;

	.list {
		display: flex;
		align-items: center;
		gap: 2rem;

		li {
			padding-bottom: 16px;
			border-bottom: 1px solid transparent;
		}
		li.active {
			border-bottom: 1px solid #D4A655;
			color: #D4A655;
			font-weight: 600;
		}
	}
	svg {
		width: 24px;
		height: 24px;
	}

}

/* Sidebar seo modal styles and common styles */

.sbs_btn__group button{
	padding: 0.4rem 1.5rem;
	border-radius: 4px;
	background-color: transparent;
	border: 1px solid #707170;
	color: #707170;
	box-shadow: none;
	outline: none;
	font-size: 1rem;
	transition: all 0.3s ease-in-out;
}

.sbs_btn__group button:not(.active):hover{
	background-color: #efefef;
}

.sbs_btn__group button.active{
	color: #ffffff;
	background-color: var(--primary-color);
	border-color: var(--primary-color);
}

.invalid-group .control-field{
	border-color: #d92d20;
}

/* End sidebar seo modal styles and common styles */