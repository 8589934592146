// Directions
$dir: ltr;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
@import "react-image-crop/src/ReactCrop.scss";
html {
	--right: right;
	--left: left;
	--dir: ltr;
	--productListTranslate: 150%;
	--percentage: 100%;
	--icx-left-solid-arrow: "\e903";
	--icx-right-solid-arrow: "\e904";
	--icx-arrow-right: "\e908";
}

@import "~bootstrap";
// Base Files
@import "base/variables";
@import "base/reset";
@import "base/functions";
@import "base/mixins";
@import "base/colors";
@import "base/typography";
@import "base/icomoon";
@import "base/general";
@import "base/custom-modal";
// Shared Components
@import "shared/all";
@import "shared/shared";
// Modules
@import "./modules/zayara-forum-style";
@import "./modules/auth/all";
@import "./modules/ProductBuilder/all";
@import "./modules/Reservation/all";
@import "./modules/TeamManagement/all";
@import "./modules/ProudectBuilderList/all";
@import "./modules/Flights/all";
@import "./modules/UserProfile/all";
@import "./modules/MarketPlace/all";
@import "./modules/Payment/all";
@import "./modules/inventory/all";
@import "./modules/Dashboard/all";
@import "./modules/interests/all";
@import "./modules/backOffice/all";
@import "./modules/statistics/all";
@import "./modules/notifaction/all";
@import "./modules/custom/all";
@import "./modules/responsive/all";
@import "./modules/progressCircular/all";
@import "./modules/Application/all";
@import "./modules/BestDeals/all";
@import "./modules/Visa/all";
@import "./modules/WebBuilder/all";
@import "./modules/about-us/all";
@import "./modules/promo/all";
@import "./modules/TDSVisa/all";
@import "./modules/UmrahOperations/all";
@import "./modules/serviceBuilder/all";
@import "./modules/CRM/all";
@import "./modules/SubscriptionPlan/all";
@import "./modules//Subscription/all";
@import "./modules/TripPlanner/all";
@import "./modules/safaVisa/all";
@import "./modules//B2C/all";
@import "./modules/requests/styles.scss";
@import "./modules/webPagesRequests/all";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&display=swap");
html[dir="rtl"] {
	--dir: rtl;
	--left: right;
	--right: left;
	--icx-left-solid-arrow: "\e904";
	--icx-right-solid-arrow: "\e903";
	--icx-arrow-right: "\e908";
	--productListTranslate: -150%;
	--percentage: -100%;
	body {
		font-family: "Cairo", sans-serif;
		height: 100%;
	}
}

.navbar {
	position: sticky;
	top: 0;
	.image-uploader {
		.image-uploader__outline {
			width: 70px;
			height: 70px;
		}
		.img-holder {
			width: 50px;
			height: 50px;
		}
		.image-uploader__plus-btn {
			font-size: 20px;
			width: 30px;
			height: 30px;
			cursor: pointer;
		}
	}
}

.SingleDatePicker_picker {
	z-index: 99999999999999999999 !important;
}

.link_action {
	font-size: 14px;
	font-weight: 500;
	margin: 0 5px;
	i {
		font-size: 20px;
	}
}

.bg-transparent {
	padding: 5px 0;
	background-color: transparent !important;
}

.modal-sm {
	max-width: 350px !important;
}

.title-modal-sm {
	font-size: 12px;
	text-align: justify;
	font-weight: 200;
}

.mt-10 {
	margin-top: 10% !important;
}

.custom-padding-15 td {
	padding: 20px 10px !important;
}

.cartItem {
	--bs-gutter-x: 1.5rem;
	background: #fff;
	padding: 10px;
}

.cartItemQuantity,
.proceed {
	background: transparent;
}

.DateInput__disabled {
	border: none;
	background: #f4f4f4 !important;
}

.product-content-items .days-box {
	width: 95px;
}

.product-country-from {
	width: 25%;
}

.product-country-to {
	width: 25%;
}

.Itinerary {
	.fa-plane::after {
		[dir="rtl"] & {
			left: 50% !important;
			right: unset;
		}
	}
}

.modal-header .close {
	[dir="rtl"] & {
		margin: 0rem 0rem !important;
		padding: 0 !important;
	}
}

.custom-table td:first-child {
	[dir="rtl"] & {
		border-top-left-radius: unset;
		border-bottom-left-radius: unset;
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}
}

.DateInput {
	background: transparent !important;
}

.SingleDatePickerInput__disabled {
	background-color: transparent !important;
}

.main-filter {
	background-color: #fafafa;
	border-top: 1px solid #dfdfdf;
	border-bottom: 1px solid #dfdfdf;
	padding: 9px 0 !important;
}

.hotel-details .progressbar-info .btn-pay {
	position: relative;
	right: 21px;
	left: unset;
	[dir="rtl"] & {
		position: relative;
		left: 21px;
		right: unset;
	}
}

.hotel-details .progressbar-info .item-name {
	font-size: 14px;
	font-weight: 600;
	margin: 0px -25px !important;
}

.mr-3 {
	[dir="rtl"] & {
		margin-right: unset !important;
		margin-left: 1rem !important;
	}
}

.main-tabs {
	padding: 0.5rem;
	background: #fff;
	// overflow: hidden;
	border-radius: 8px !important;
	border: none;
}

.payModal .check-box-content .form-check-input {
	margin: 0 !important;
	position: relative;
	top: 6px;
	right: 4px;
}

.radios {
	display: flex;
	justify-content: center;
	margin-bottom: 25px;
	[dir="rtl"] & {
		text-align: right;
	}
}

.fixHandleCreate {
	.modal-footer {
		display: block !important;
		width: 100%;
		text-align: right;
		.btn-primary {
			float: right;
		}
		[dir="rtl"] & {
			text-align: left;
			.btn-primary {
				float: left;
			}
		}
	}
	.modal-content {
		[dir="rtl"] & {
			text-align: start;
		}
	}
}

.boxChild {
	min-width: 320px !important;
	display: block;
}

.boxHeader {
	background: gray;
}

.flex-prepend {
	padding: 12px 18px;
	/* flex: auto; */
	border-radius: 5px;
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	width: 1%;
	margin-bottom: 0;
}

.main-tabs1 {
	display: flex;
	flex-direction: row;
}

.HotelSearch label {
	text-transform: capitalize;
	font-weight: 600;
}

.no-gutters {
	width: 100%;
}

.SingleDatePicker {
	width: 100% !important;
}

.SingleDatePickerInput {
	width: 100% !important;
}

.DateInput {
	width: 100% !important;
	max-width: 100% !important;
}

// .newFixPadding {
// 	padding: 9px 10px !important;
// }
.innerPadding {
	padding: 6px 10px !important;
}
.DateInput_input {
	background: transparent !important;
}

.bg-Rooms {
	background-color: #f7f7f7;
}

.container-centerLabel {
	display: flex;
	height: 100%;
	flex-direction: row;
	align-items: center;
	flex-flow: row wrap;
}

.small-title {
	line-height: 0.8;
	font-size: 14px;
	margin-top: -9px;
	color: #979797;
}

.box-child {
	position: absolute;
	width: 100%;
	background: white;
	z-index: 209999999;
	bottom: 45px;
	max-height: 400px;
	overflow-y: scroll;
	padding: 25px;
	border-radius: 8px;
	box-shadow: 0 1px 3px grey;
	max-height: 270px;
}

.roomsNumber {
	color: #cb9a51;
	margin: 0 4px;
}

.adultNumber {
	color: #0c3b5c;
	font-size: 14px;
	font-weight: 400;
	margin: 0 4px;
}

.ageInput .control-field__body {
	padding: 6px 7px !important;
}

.ageInput .control-field {
	height: 35px !important;
}

.text-danger-custom {
	color: #ff6868;
	font-size: 14px;
}

.Soon-span {
	background: #dea237;
	background: linear-gradient(180deg, #daa249 0%, #cc9a50 100%);
	position: absolute;
	color: #fff;
	top: -15px;
	padding: 1px 5px;
	font-size: 14px;
	border-radius: 5px;
}

.search-banner {
	width: 20%;
	height: -moz-fit-content;
	height: 66vh;
	/* background-position: center; */
	background-position: center;
	background-size: cover;
}

[dir="rtl"] .search-banner {
	border-radius: 6px 0px 0px 6px;
}

[dir="ltr"] .search-banner {
	border-radius: 0px 6px 6px 0px;
}

.borderdanger {
	border: 1px solid#ff5f59 !important;
}

.new-color {
	color: #cb9a51;
}

.border-d {
	border-top: 1px solid #ebebeb;
	display: block;
}

.span-warining {
	color: gray;
	padding: 5px 15px;
	font-size: 20px;
	cursor: pointer;
}

.span-comment {
	color: gray;
	padding: 5px 15px;
	font-size: 20px;
	cursor: pointer;
}

.span-warining:hover {
	color: #ce7c0d;
}

.span-comment:hover {
	color: #ce7c0d;
}

.span-Request {
	background-color: #ce7c0d;
	padding: 4px 6px;
	color: #fff;
	border-radius: 6px;
	line-height: 0.9;
	height: 25px;
	font-size: 13px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: auto;
}

.span-Available {
	background-color: #28a745;
	padding: 4px 6px;
	color: #fff;
	border-radius: 6px;
	line-height: 0.9;
	height: 25px;
	font-size: 13px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: auto;
}

.bg-text {
	background-color: #f1f4f7;
}

.w-105 {
	width: 190px;
	display: inline-block;
}

.span-info {
	color: gray;
	cursor: pointer;
}

.span-info:hover {
	color: #ce7c0d;
	cursor: pointer;
}

.room-description {
	padding: 15px;
	width: 100%;
	background-color: #f1f4f7;
	color: #2d2d2d;
	font-weight: 500;
}

.select-room .custom-control-field {
	border: 1px solid #28a745 !important;
	background-color: #e7f5ea !important;
}

.span-count {
	float: right;
	background: #cb9a51;
	width: 23px;
	text-align: center;
	color: #fff;
	border-radius: 20px;
}

.price-model {
	margin-top: 10%;
}
.payment-modal {
	max-width: max-content;
}
.box-item {
	margin: 0 8px;
	display: inline-flex;
	width: 200px;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	position: relative;
	border: 1px solid #80808066;
	border-radius: 8px;
	padding: 15px;
	height: 160px !important;
}

.box-item label {
	color: #3b4b5d;
	font-weight: 700;
}

.box-item input[type="radio"] {
	position: absolute !important;
	left: 6px;
	top: 8px;
}

.checked-active {
	background-color: #f7f1e9;
}

.SpecialRequests .form-check {
	display: inline-flex;
	width: -moz-fit-content;
	width: fit-content;
	margin: 0;
	min-width: 50%;
}

.react-switch {
	box-shadow: 0 1px 2px grey;
}

.border-top {
	border-top: 1px solid rgba(128, 128, 128, 0.56);
}

.span-remove {
	margin-top: 20px;
	display: block;
	background: #ff6868;
	width: 20px;
	height: 20px;
	text-align: center;
	font-size: 12px;
	color: #fff;
	border-radius: 12px;
	padding: 2px;
}
.span-remove-services {
	color: #ff6868;
	font-size: 12px;
	padding: 2px;
	width: 20px;
	height: 20px;
	text-align: center;
}
.icon-items-section-services {
	i {
		font-size: 15px;
		cursor: pointer;
	}
}
.roomTypesTable {
	td {
		padding: 0 0.7rem;
		vertical-align: middle;
		border-top: 1px solid #dee2e6;
	}
	th {
		padding: 0.75rem;
		vertical-align: middle;
		color: #112e53;
	}
	.hover-roomTypesTable:hover {
		background-color: #faf2e8;
		cursor: pointer;
	}
}

.Cost-title {
	color: #0c3b5c;
	font-size: 18px;
	line-height: 0.9;
	margin-bottom: 5px;
}

.price-title {
	color: #28a745;
	font-size: 20px;
	line-height: 0.9;
}

.title-terms {
	font-size: 16px;
	span {
		color: #cb9a51;
	}
}

.boxCancellation {
	text-transform: capitalize;
	padding: 8px;
	height: 62.54px;
	background: #ebebeb;
	text-align: center;
	border-radius: 6px;
	p {
		text-transform: capitalize;
	}
}

.k-market-search-container .box-Umrah-Plus {
	height: 52px;
	width: 100%;
	background-color: #ebebeb;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	padding: 15px;
	input {
		width: 20px;
		height: 20px;
		// accent-color: #b08e26;
		accent-color: #bc6221;
		color: #2d2d2d;
	}
}

.activeText {
	// color: #cb9a51 !important;
	color: #bc6221 !important;
	font-weight: bolder;
}

.btn-backNew {
	border: 1px solid;
	display: inline-flex;
	float: right;
	align-items: center;
	align-content: center;
	font-size: 14px;
}

.hotel-amenities-new {
	margin-bottom: 15px;
	background-color: #f1f4f7;
	padding: 12px 12px !important;
	box-shadow: 2px 2px 5px rgb(0 0 0 / 11%);
}

.hotel-amenities-new ul li,
.hotel-amenities-new ul li {
	background-color: transparent;
	border: none;
	padding: 5px 7px;
	display: inline-block;
	margin: 2px 0;
	width: 33%;
	i {
		color: gray;
	}
}

.mt-8 {
	margin-top: 5%;
}

.head-room {
	justify-content: space-between;
	display: flex;
	align-items: center;
	background: #f4f4f4;
	padding: 8px;
	border-radius: 4px;
}

.text-custome {
	color: #cb9a51 !important;
}

.bg-Ground {
	text-transform: capitalize;
	background-color: #e7f5ea;
	padding: 20px 10px;
	p {
		text-transform: capitalize;
		color: #28a745;
	}
}

.bg-dangerr {
	background-color: #f3d6d7;
	padding: 20px 10px;
	p {
		text-transform: capitalize;
		color: #ff6868;
	}
}

.bg-dangerNew {
	color: #ff6868;
	background-color: #f3d6d7;
	p {
		text-transform: capitalize;
		color: #ff6868;
	}
}

.text-hidden {
	visibility: hidden !important;
}

.float-custome {
	float: right;
	[dir="rtl"] & {
		float: left;
	}
}

.Text-Booking {
	.input-group {
		overflow: hidden !important;
	}
}

.listAuto {
	position: absolute;
	background: #fff;
	z-index: 9999;
	border: 1px solid #8080806e;
	border-radius: 9px;
	overflow: hidden;
	overflow-y: auto;
	max-height: 350px;
	/* top: 79px; */
	width: 100%;
	li {
		width: 100%;
		padding: 12px 7px;
		display: flex;
		border-bottom: 1px solid rgba(128, 128, 128, 0.414);
		img {
			margin: 0 4px;
		}
		&:hover {
			background-color: #0c3b5c98 !important;
			color: #fff;
			cursor: pointer;
		}
	}
}

.fix-flag {
	position: absolute;
	width: 35px;
	height: 22px;
	left: 15px;
	top: 11px;
	[dir="rtl"] & {
		left: unset;
		top: 12px;
		right: 15px;
	}
	svg {
		width: 35px;
		height: 22px;
	}
}
.fix-flag-inner {
	position: absolute;
	width: 40px;
	height: 25px;
	svg {
		width: 28px;
		height: 18px;
	}
}

.show > .bell-notifaction .custom-notification img {
	filter: invert(91%) sepia(0%) saturate(0%) hue-rotate(346deg) brightness(104%)
		contrast(206%);
}

.fa-angle-right {
	[dir="rtl"] & {
		transform: rotateY(179deg);
	}
}

.btn.disabled,
.btn:disabled {
	background: transparent
		linear-gradient(180deg, #767676 0%, #979797 97%, #28a745 100%) 0% 0%
		no-repeat padding-box !important;
	border: none !important;
}

.fix_place_arrow {
	position: absolute;
	right: 10px;
	top: 12px;
	[dir="rtl"] & {
		right: inherit;
		left: 4px;
		right: unset !important;
	}
}

input::placeholder {
	color: #dddcdc !important;
	display: block !important;
}

// button {
//     text-transform: uppercase !important;
// }

@media print {
	.TechnicalSupport-width {
		display: none !important;
	}
	#sideNav {
		display: none !important;
	}
	.container_layout {
		width: 100% !important;
	}
}

.new-price-number {
	border: 1px solid #80808063;
	// padding: 5px 30px 0 10px;
	position: relative;
	bottom: 28px;
	min-width: 318px;
	width: fit-content;
	background: #fff;
	/* right: 40px; */
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	justify-content: flex-end;
	margin-left: auto;
}

.btn-Product-Filter {
	padding: 10px 24px;
	background-color: #d4a655;
	border: none;
	border-radius: 5px;
	color: white;
	display: flex;
	justify-content: center;
	gap: 8px;
	// width: 190px;
	font-weight: 700;
	// justify-content: space-between;
}

.title-Filter {
	font-weight: 700 !important;
	font-family: sans-serif;
	font-size: 16px !important;
}

[dir="rtl"] .title-Filter {
	font-weight: 700 !important;
	font-size: 16px !important;
	font-family: "Cairo";
}

.header-Filter {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #f7f6f6;
	padding-bottom: 1rem;
	h1 {
		font-family: inherit !important;
		font-size: 24px !important;
	}
}

.btn-apply {
	background-color: #0c3b5c;
	border: none;
	border-radius: 5px;
	color: white;
	padding: 4px 9px;
	text-transform: capitalize !important;
	i {
		margin-right: 6px;
	}
	&:hover {
		color: #fff !important;
	}
}

.btn-green-300 {
	background-color: #2da94a;
	color: #ffffff;
	font-size: 16px;
	border: none;
	&:hover {
		color: #fff !important;
	}
}

.main-filter {
	background-color: #fafafa;
	box-shadow: 0px 1px 4px #adadad80;
}

.tr-hover:hover {
	background-color: #faf2e8 !important;
}

.ReactCrop__crop-selection {
	background-color: #00800056;
}

.th-trip-mang {
	color: #707170;
	background: #f7f6f6;
	th {
		vertical-align: middle !important;
		font-weight: 700;
		border-top: 0 !important;
		border-bottom: 0px !important;
	}
}

.up-down {
	position: relative;
}

.tb-trip-mangment {
	border: 1px solid #eee;
}

.tb-traip-products {
	img {
		padding: 3px;
	}
}

.tr-trips-mangment {
	td:nth-child(1) {
		padding-left: 10px;
	}
	td {
		vertical-align: middle !important;
		padding: 0;
	}
}

.rotet-plane {
	transform: rotate(-45deg);
}

.add-price-underline {
	color: red;
	text-decoration: underline;
	font-size: 17px;
	font-weight: 300;
}

.btn-unpublished {
	color: white;
	width: fit-content;
	padding: 0 5px;
	background-color: #ce7c0d;
	border-radius: 5px;
}

.edit-product {
	// margin-right: 20px;
	color: #ce7c0d;
	border: none;
	background-color: inherit;
	text-transform: capitalize !important;
}

.pegntion-trip:hover {
	color: white;
	background-color: #112e53;
}

.pegntion-trip {
	display: inline-block;
	padding: 0px 5px;
}

.previos-page {
	transform: rotate(180deg);
}

.page-list {
	text-align: right;
}

.btn-reset-filter {
	border: 1px solid var(--f-6-ebda, #eed9ba);
	background: var(--faf-4-eb, #faf4eb);
	border-radius: 5px;
	padding: 8px 9px;
	min-width: 92px;
	height: 32px;
	text-align: center;
	text-transform: capitalize;
	font-size: 14px;
	color: #6a4d1b;
	gap: 5px;
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.hd-bulid-pro {
	background-color: white !important;
	// margin-top: -3rem;
	// height: 200px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	.bulid-pro-inputs {
		height: auto;
		margin-top: 30px;
		align-items: stretch;
		padding-top: 20px;
		width: 94% !important;
		margin-left: auto;
		margin-right: auto;
		button {
			margin-top: 8px;
		}
	}
	.builder-date-picker {
		.control-field__body {
			padding: 12px;
		}
		[dir="rtl"] & .DateRangePicker {
			padding-inline-start: 22px;
		}
		.DateInput {
			padding: 0 4px;
		}
	}
}

.back-to-trips-btn {
	background-color: white;
	color: gray;
	border: 1px solid grey;
	border-radius: 3px;
	text-transform: capitalize !important;
	padding: 2px 14px;
	i {
		padding-right: 4px;
	}
}

.builder-wrapper-content {
	// border-radius: 12px;
	background-color: white !important;
	// border: 1px solid #cfcfcf;
}

.circle-bg {
	background-color: #ebebeb !important;
}

.sub-add {
	position: relative;
	top: 7px;
	text-transform: capitalize;
	font-size: 14px !important;
	font-family: sans-serif;
	color: #3e3a3a !important;
}

.title-wrapper-builder {
	font-size: 16px !important;
	font-weight: 800 !important;
	color: #000 !important;
	text-transform: capitalize;
}

.empty-wrapper-bulder {
	h1 {
		font-size: 20px;
		font-weight: 300;
	}
}

.product-build__button-actions .actions-buttons-group .save-changed-p-b {
	float: right;
	margin-right: 71px !important;
	font-size: 20px;
	background: #cb9a51 !important;
	&.disabled,
	&:disabled {
		background: rgb(218, 215, 215) !important;
		color: rgb(165, 163, 163) !important;
	}
}

.bg-wrapper-builder {
	background-color: #f7f8fa !important;
}

.product-build__leftside-menu {
	.om-bg-white {
		background-color: white !important;
	}
}

.empty-box {
	width: 1155px;
	height: 555px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.blue-txt-item {
	font-size: 15px !important;
	color: #0c3b5c !important;
	text-transform: capitalize;
	font-weight: 600;
}

.htel-date {
	font-size: 14px !important;
	font-family: sans-serif;
	color: #3e3a3a !important;
}

.btn-text-wrapper {
	padding: 6px 26px;
	font-size: 20px;
	background: #cb9a51 !important;
	color: white;
}

.product-element_form {
	label {
		font-size: large;
		font-family: sans-serif;
	}
	.fix_place_arrow {
		right: 7px;
	}
	/* 	&::placeholder {
		color: #28a745;
		font-weight: bold;
	} */
}

.product-content-items .product-arrival {
	.fa-circle {
		color: gray;
		position: relative;
		&::after {
			background-color: gray;
		}
	}
	.transitons-count {
		position: relative;
		background-color: rgb(40 167 69);
		padding: 2px 14px;
		border-radius: 5px;
		font-weight: 700;
		color: white;
		z-index: 100 !important;
		&::after {
			content: "";
			position: absolute;
			top: 10px;
			inset-inline-start: 60px;
			width: 75px;
			height: 1px;
			background-color: gray;
			z-index: -1 !important;
		}
	}
}

.btn-add-transit {
	color: #28a745 !important;
	font-size: 16px !important;
	text-transform: capitalize !important;
	font-family: sans-serif;
	i {
		padding: 4px;
	}
	&:disabled {
		color: #28a745 !important;
		font-size: 16px !important;
		text-transform: capitalize !important;
		font-family: sans-serif;
		background: transparent !important;
	}
}

.product-content-items {
	.nights-icon {
		background-color: #ebebeb;
		margin-right: 70px;
		border: 0;
		font-size: 18px !important;
		font-family: sans-serif !important;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 125px;
		height: 80px;
	}
	.nights-box {
		position: relative;
		color: #979797;
		&::after {
			content: "";
			position: absolute;
			top: 14px;
			left: 34px;
			width: 94px;
			height: 1px;
			transform: rotate(90deg);
			background-color: #dfdfdf;
		}
		&::before {
			content: "";
			position: absolute;
			top: 14px;
			right: 60px;
			width: 96px;
			height: 1px;
			transform: rotate(90deg);
			background-color: #dfdfdf;
		}
	}
}

.blue-drop-btn {
	color: #0c3b5c;
}

.gold-title {
	font-size: 22px;
	text-transform: uppercase;
	color: #cb9a51;
	font-weight: 600;
}

.black-title-builder {
	font-size: 18px;
	text-transform: capitalize;
	color: black;
	font-weight: 400;
}

.icon-pro-Itinerary {
	width: 44px;
	height: 44px;
	margin-left: 14px;
	background-color: #ebebeb !important;
	z-index: 1;
}

.txt-dis-tabs {
	font-size: 17px !important;
	color: #2d2d2d !important;
	font-weight: normal !important;
}

.all-label-filter {
	label {
		font-size: 15px !important;
		color: #2d2d2d !important;
	}
}

/* .table-update tbody td {
    color: #464646;
    font-size: 14px;

} */

.all-txt-table {
	td {
		font-size: 14px !important;
		color: #464646 !important;
		font-weight: normal;
	}
}

.hd-title-model {
	h5 {
		font-size: 16px !important;
		font-weight: 500 !important;
		color: #1b1e21 !important;
	}
}

.p-remark {
	font-size: 16px !important;
	font-weight: 400 !important;
	color: #1b1e21 !important;
	text-transform: lowercase;
	font-family: sans-serif;
}

.model-remark {
	min-width: 700px;
}

.new-fz {
	font-family: sans-serif !important;
	font-size: 20px !important;
}

.sign-up-label {
	font-size: 16px !important;
	font-family: sans-serif !important;
	button {
		font-size: 16px !important;
		font-family: sans-serif !important;
	}
	label {
		font-size: 16px !important;
		font-family: sans-serif !important;
	}
}

.wpwl-container {
	position: relative;
	background: #f4f4f4 !important;
}

.sans-font {
	font-family: sans-serif !important;
}

.font-s20 {
	font-size: 20px;
}

.mtFIX-2 {
	margin: 16% auto 2% !important;
}
.Mobile_SBS {
	display: none;
	position: fixed;
	width: 100%;
	z-index: 999999999;
	text-align: center;
	height: 100%;
	background: #fff;
}

@media (max-width: 550px) and (min-width: 320px) {
	.moWA-none {
		display: none !important;
	}
	.Mobile_SBS {
		display: block !important;
	}
	.search-services-wrapper .services-list .service-item {
		width: 48%;
		font-size: 15px;
		padding: 12px 0 !important;
	}
}

@media (max-width: 767px) and (min-width: 320px) and (orientation: landscape) {
	.moWA-none {
		display: none !important;
	}
	.Mobile_SBS {
		display: block !important;
	}
}
@media (max-width: 550px) and (min-width: 320px) {
	.btn-Product-Filter {
		padding: 5px 45px;
	}

	.hotel-map-filters,
	.hotel-map-list {
		height: calc(50vh - (80px + 0.5rem));
		padding-bottom: 2rem;
		overflow: hidden;
		overflow-y: scroll;
		margin-top: 20px;
	}

	.hotel-map {
		height: auto !important;
	}

	.hotel-map .leaflet-container {
		margin-top: 20px !important;
		height: 350px !important;
		width: 90% !important;
		display: block;
		margin: auto;
	}
}

html[dir="rtl"] {
	.btn-backNew {
		float: left !important;
	}
	.float-right {
		float: left !important;
	}
}

.summary-box {
	p {
		display: flex;
		justify-content: space-between;
	}
}

.modal-header {
	h5 {
		font-size: 18px;
		font-weight: 400;
	}
}

.db-title {
	font-size: 20px !important;
	font-family: sans-serif;
	color: #0c3b5c !important;
}

.db-style {
	font-family: sans-serif;
	font-size: 16px;
	color: #0c3b5c !important;
}

.tex-warn {
	color: #cb9a51 !important;
}

.O-Email-box {
	h1 {
		font-family: sans-serif !important;
	}
	.register-email-confirmation {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
}

.x-model {
	margin-left: auto;
}

.add-guest {
	font-size: 16px !important;
	font-weight: 600 !important;
	i {
		font-size: 14px;
		width: 18px;
		height: 18px;
		line-height: 18px;
	}
}

.rememberme-label {
	font-size: 16px !important;
}

.price-text {
	font-size: 18px;
	color: #0c3b5c !important;
	font-weight: 500;
}

.old-price {
	color: red;
	font-size: 15px;
	font-weight: 500;
	/* text-decoration: wavy; */
	text-decoration-line: line-through;
}

.text-Nights {
	font-weight: 400;
}

// .new-price-number a {
// 	width: 20%;
// }

// .new-price-number a:hover {
// 	width: 20%;
// 	color: #FFF !important;
// 	background-color: #D4A655 !important;
// }

.custom-color,
.custom-select-text {
	color: #cb9a51 !important;
}

.custom-border-color {
	border-color: #cb9a51 !important;
	//border-color: $main-bg-sbs !important;
}

.main-nav-sbs {
	background-color: #ebebeb;
	.nav {
		background: #ebebeb;
	}
	.nav-link.active {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 58px !important;
		background: $main-bg-sbs !important;
		border-radius: 0 !important;
		border: 1px solid #dfdfdf;
		border-bottom: 0;
		color: #fff !important;
		font-weight: 700;
		span {
			color: #fff !important;
		}
	}
	.nav-item {
		min-height: 58px;
		width: 16.6667%;
		text-align: center;
		margin-bottom: 0px !important;
		margin-top: 0px !important;
		background: #ebebeb !important;
		.nav-link {
			/* border: none; */
			color: #324356;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 58px;
			min-width: auto !important;
			border: 0 !important;
			font-weight: 600;
			background-color: #cbcbcb;
			border-radius: 0 !important;
		}
	}
}

.code-num {
	float: left;
	direction: ltr;
}

.select_component {
	position: relative;
	width: 100%;
	border-radius: 5px;
	background-color: #ffffff;
	border: 1px solid transparent;
	border-radius: 0.3125rem;
	font-size: 12px;
	height: 44px;
}

.control-field select:not(:checked) {
	color: #000 !important;
}

.sm-taps {
	width: 28%;
	.nav.nav-tabs {
		border-radius: 8px;
		overflow: hidden;
		.nav-item {
			width: 50%;
			min-height: 41px;
			a {
				min-height: 30px !important;
			}
		}
	}
}

.offerlayout {
	position: fixed;
	right: 0;
	bottom: 0px;
	border-top: 10px solid #cc9a50;
	width: 100%;
	z-index: 555555555555;
	background: #f7f7f7;
	min-height: 0;
	.btn-toggle {
		position: absolute;
		top: -40px;
		right: 50px;
		background-color: #cc9a50;
		color: #fff;
		border: none;
		font-size: small;
		padding: 5px;
		text-transform: capitalize !important;
	}
	.btn-close {
		position: absolute;
		top: -30px;
		right: 50px;
		background-color: #cc9a50;
		color: #fff;
		border: none;
		font-size: small;
		padding: 0px 15px;
	}
	.btn-close::after {
		content: "";
		position: absolute;
		top: -10px;
		left: 0px;
		height: 0;
		width: 0;
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		border-bottom: 10px solid #cc9a50;
	}
	.ads-head {
		.ads-type {
			color: #c30101;
			font-size: 18px;
			font-weight: 700;
		}
		.ads-date {
			font-weight: 700;
			font-size: 16px;
			columns: #0000;
		}
	}
	.slider-row {
		position: relative;
		display: flex;
		gap: 1rem;
		display: flex;
		transition: all 250ms linear;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	.card-item {
		margin-top: 10px;
		.img-card {
			width: 260px;
			height: 115px;
		}
		.card-info {
			.name {
				font-weight: 700;
				color: #0c3b5c;
				margin-top: 10px;
				font-size: 18px;
				white-space: nowrap;
				width: 250px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.date {
				color: #c30101;
				font-size: 13px;
			}
			.date_ar {
				color: #c30101;
				font-size: 13px;
				display: flex;
			}
			.btn-card {
				margin-top: 10px;
				text-transform: capitalize !important;
				border: 1px solid #cc9a50;
				border-radius: 5px;
				color: #cc9a50;
				padding: 2px 12px;
				margin: 5px auto !important;
				display: block;
				a {
					color: #cc9a50 !important;
				}
			}
			.btn-card:hover {
				background-color: #cc9a50;
				color: #fff;
				a {
					color: #fff !important;
				}
			}
		}
	}
	.react-multi-carousel-track {
		.react-multi-carousel-item {
			flex: 1 1 auto;
			position: relative;
			width: 278px;
			// width: 35% !important;
		}
	}
}

html[dir="rtl"] body .right-slider-arrow .fa-chevron-right {
	transform: rotateY(0deg);
}

.ads-date {
	direction: rtl;
}

.container_layout {
	margin: auto;
	background-color: #f5f6f7;
	min-height: calc(100vh - 181px);
	padding: $main-container-padding;

	@media screen and (max-width: 767px) {
		padding: 12px;
	}
}

.app-footer {
	position: relative;
	padding: 16px 24px;
	box-shadow: 0px -1px 12px 0px #0000000a;
	background-color: #112233;
	color: #fff;

	.footer-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 0 1rem !important;
		background: transparent !important;
	}
	.terms {
		display: flex;
		align-items: center;
		gap: 24px;
		.terms-link {
			font-size: 16px;
			font-weight: 500;
			color: #d4a655;
			text-decoration: underline !important;
		}
	}

	.social-platforms {
		display: flex;
		align-items: center;
		margin-inline-end: 4rem;
		gap: 20px;

		.platform {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 30px;
			height: 30px;
			border-radius: 50%;
			background-color: #d59f4c;
		}
	}
	.copyrights {
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 8px;
		gap: 4px;
		border-top: 1px solid #f0f0f01a;
	}
	.help-live-chat {
		bottom: 35px;
		inset-inline-end: 0;
		position: fixed;
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
}

.light-opacity {
	opacity: 0.4;
}

.left-slider-arrow,
.right-slider-arrow {
	position: absolute;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);
	width: 48px;
	height: 48px;
	border-radius: 24px;
	background-color: white;
	border: 1px solid #ddd;
}

.slider-row-wrapper {
	overflow: hidden;
	position: relative;
	width: 95%;
	height: 100%;
	margin: auto;
}

.left-slider-arrow {
	left: 10vw;
}

.right-slider-arrow {
	right: 10vw;
}

@media (min-width: 992px) and (max-width: 1199.98px) {
	.slider-row-wrapper {
		width: 85%;
	}
	.left-slider-arrow {
		left: 1vw;
	}
	.right-slider-arrow {
		right: 1vw;
	}
}

@media (min-width: 1200px) and (max-width: 1799px) {
	.slider-row-wrapper {
		width: 96%;
		margin-left: auto;
		padding-left: 3px;
		[dir="rtl"] & {
			padding-right: 3px;
		}
	}
	.left-slider-arrow {
		left: 5vw;
	}
	.right-slider-arrow {
		right: 5vw;
	}
}

// @media (min-width: 1800px) and (max-width: 2560px) {
// 	.offerlayout {
// 		bottom: -120px !important;
// 	}
// }

@media print {
	.TechnicalSupport-width {
		display: none !important;
	}
	#sideNav {
		display: none !important;
	}
	.container_layout {
		width: 100% !important;
	}
}
.img-card {
	width: 100%;
}

.card-info {
	width: 100%;
}

html[dir="rtl"] {
	.container_layout {
		margin-right: auto;
		margin-left: 0;
	}
	.text-left {
		text-align: right !important;
	}
}

.lds-dual-ring {
	display: inline-block;
	width: 80px;
	height: 80px;
}

.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid rgb(28, 43, 245);
	border-color: rgb(37, 72, 168) transparent rgb(35, 55, 235) transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}

.mr-7 {
	margin-right: -7px;
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.disabled-li {
	opacity: 0.5;
}

.closed .disabled-li.sidenav_item:hover {
	width: auto !important;
}

.closed .disabled-li .sidenav_link:hover .dash-text {
	visibility: hidden;
}

.closed .disabled-li .sidenav_link:hover .sidenav_arrow {
	visibility: hidden;
}

.closed .disabled-li .sidenav_link:hover .sidenav_dropdown {
	box-shadow: none !important;
}

.disabled-li .Soon-span {
	top: 0px !important;
	font-size: 10px !important;
}

.bell-notifaction.disabled {
	background: transparent !important;
	opacity: 0.3;
}

.elementToFadeInAndOut {
	-webkit-animation: fadeinout 1.5s linear infinite;
	animation: fadeinout 1.5s linear infinite;
	opacity: 0;
}

@-webkit-keyframes fadeinout {
	50% {
		opacity: 1;
	}
}

@keyframes fadeinout {
	50% {
		opacity: 1;
	}
}

.market-search-guide-btn {
	position: fixed;
	right: 0;
	top: 90px;
	padding: 5px 18px;
	background: #daa249;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #fff;
	text-transform: capitalize !important;
}
.market-search-guide-btn:hover {
	color: #fff;
}
.market-search-guide-btn i {
	font-size: 24px;
	color: #fff;
	margin-bottom: 2px;
}
[dir="rtl"] .market-search-guide-btn {
	right: initial;
	left: 0;
}
.introjs-tooltipReferenceLayer *,
.introjs-tooltipReferenceLayer {
	font-family: inherit !important;
	font-size: 14px !important;
}

.TechnicalSupport-width {
	right: 40px;
	left: unset;
}
[dir="rtl"] .TechnicalSupport-width {
	left: 40px;
	right: unset;
}

@media (min-width: 320px) and (max-width: 425px) {
	.offerlayout .card-item {
		min-width: 100% !important;
	}
	.ModalGroup-Modal {
		top: 0% !important;
	}
}

@media (min-width: 320px) and (max-width: 576px) {
	.TechnicalSupport-width {
		right: 0;
		left: unset;
	}
	[dir="rtl"] .TechnicalSupport-width {
		left: 0;
		right: unset;
	}
	.text-mobileNew {
		font-size: 12px !important;
	}
}

#root {
	position: relative !important;
}

.ms-0 {
	margin-inline-start: 0 !important;
}
.ms-1 {
	margin-inline-start: ($var-spacer * 0.25) !important;
}
.ms-2 {
	margin-inline-start: ($var-spacer * 0.5) !important;
}
.ms-3 {
	margin-inline-start: $var-spacer !important;
}
.ms-4 {
	margin-inline-start: ($var-spacer * 1.5) !important;
}
.ms-5 {
	margin-inline-start: ($var-spacer * 3) !important;
}
////
.me-0 {
	margin-inline-end: 0 !important;
}
.me-1 {
	margin-inline-end: ($var-spacer * 0.25) !important;
}
.me-2 {
	margin-inline-end: ($var-spacer * 0.5) !important;
}
.me-3 {
	margin-inline-end: $var-spacer !important;
}
.me-4 {
	margin-inline-end: ($var-spacer * 1.5) !important;
}
.me-5 {
	margin-inline-end: ($var-spacer * 3) !important;
}

//////////////////////

.ps-1 {
	padding-inline-start: ($var-spacer * 0.25) !important;
}
.ps-2 {
	padding-inline-start: ($var-spacer * 0.5) !important;
}
.ps-3 {
	padding-inline-start: $var-spacer !important;
}
.ps-4 {
	padding-inline-start: ($var-spacer * 1.5) !important;
}
.ps-5 {
	padding-inline-start: ($var-spacer * 3) !important;
}
///////////
.pe-1 {
	padding-inline-end: ($var-spacer * 0.25) !important;
}
.pe-2 {
	padding-inline-end: ($var-spacer * 0.5) !important;
}
.pe-3 {
	padding-inline-end: $var-spacer !important;
}
.pe-4 {
	padding-inline-end: ($var-spacer * 1.5) !important;
}
.pe-5 {
	padding-inline-end: ($var-spacer * 3) !important;
}

.min-height {
	.no-gutters {
		height: auto !important;

		.control-field__select {
			.control-field {
				height: 100% !important;
			}
			div[class$="-control"] {
				max-height: 100% !important;

				div[class$="-ValueContainer"] {
					min-height: 100% !important;
					max-height: 100% !important;
				}
			}
		}
	}
}
.no-gutters {
	.control-field__select {
		div {
			margin: 0 !important;
		}
	}
}
.flag_box {
	svg {
		width: 30px;
		height: 20px;
		display: inline-block;
		margin: 0 5px;
	}
}

.invalid {
	border: 1px solid #ea1414 !important;
}

.opacity-75 {
	opacity: 0.75;
}

.opacity-5 {
	opacity: 0.5;
}

.datePicker-years {
	font-size: 14px;
	.month,
	.year {
		border: 1px solid #e3e3e3;

		background: #fff;
		select {
			border: none;
			background: #fff;
		}
	}
	.month {
		padding-inline: 20px;
	}
	.year {
		padding-inline: 5px;
	}
}
.DayPickerNavigation_button.DayPickerNavigation_button_1.DayPickerNavigation_button__horizontalDefault {
	top: 21px !important;
}

.react-datepicker-popper {
	font-size: 18px !important;
}
.btn-white-border,
.btn-white-border:hover {
	border: 1px solid #cb9a51;
	color: #cb9a51;
	font-size: 1rem;
}
.react-datepicker__input-time-container {
	font-weight: 900 !important;
}


.custom-time-field-container {
	.time-picker-dropdown {
		position: absolute;
		top: 100%;
		left: 50%; /* Center horizontally */
		transform: translateX(-50%); /* Center horizontally */
		z-index: 10;
		background-color: white;
		border: 1px solid #ccc;
		border-radius: 4px;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
		padding: 8px;
		width: 160px; /* Increased width */
	}
	
	.time-picker-column {
		width: 70px;
		max-height: calc(56px + 9.8rem);
		overflow-y: auto;
	}
	
	.time-picker-separator {
		margin: 0 8px;
	}
	
	.time-picker-option {
		padding: 4px 8px;
		border-radius: 4px;
		white-space: nowrap;
		text-align: center;
		cursor: pointer;

		&.selected-option {
			background-color: #d2ab67;
			color: white;
		}

		&:has(:not(.selected-option)):hover {
			background-color: #f0f0f0;
		}
	}
}

.input-OTP {
	height: 42px;
	border-radius: 6px;
	font-weight: 700;
	border: 1px solid gray;
	font-size: 16px;
}
.resend-otp-btn,
.resend-otp-btn:hover {
	background: transparent !important;
	color: #0372bc;
	font-size: 14px;
}
.resend-otp-btn:disabled {
	background: transparent !important;
	opacity: 0.5;
	color: #000;
}

// .modal-dialog {
//     max-width: 550px;
// }

.body-background {
	width: 100%;
	display: block;
	overflow: hidden;
}
.d-none-mo {
	display: none !important;
}
.image-shadow {
	filter: drop-shadow(1px 2px 2px #00000056);
}
.datepicker-centerd .SingleDatePicker_picker {
	right: initial !important;
}

@media (max-width: 767px) {
	.datepicker-centerd .SingleDatePicker_picker {
		right: -10px !important;
	}
}

@media (max-width: 575px) {
	.hidden-mobile {
		display: none !important;
	}
	.d-none-mo {
		display: block !important;
	}
}
.disabled-background {
	background-blend-mode: multiply;
	background-color: #000000a2;
}
.opacity5 {
	opacity: 0.5;
}
.line-hi-1 {
	line-height: 1.1;
}
.lineHeight1 {
	line-height: 1;
}
.bg-light-dark {
	background-color: #ebebeb !important;
}
.opacity-0 {
	opacity: 0;
}
.overFlowHide {
	overflow: hidden;
}
.marginTop38 {
	margin-top: 38px;
}
.marginTop34 {
	margin-top: 34px;
}
.bg-light-darker {
	background-color: #cdcdcd !important;
}
.custom-control-labeler {
	position: relative;
	margin-bottom: 0;
	vertical-align: top;
	font-weight: 600;
	text-transform: capitalize;
}
.ele-round {
	background-color: #f7f6f6;
	padding: 5px;
	border-radius: 16px;
	gap: 5px;
	display: flex;
	align-items: center;
	max-width: fit-content;
}
.tb-date {
	font-weight: 700;
	color: #030303cc;
}
.tb-date-s {
	font-size: 12px;
	color: #707170;
}
.tb-publish {
	background: rgba(1, 157, 76, 0.16);
	padding: 4px;
	color: #019d4c;
	text-align: center;
	border-radius: 4px;
	// text-decoration: underline;
	font-size: 14px;
}
.tb-unpublished {
	background-color: #f7f6f6;
	padding: 4px;
	color: #707170;
	text-align: center;
	border-radius: 4px;
	// text-decoration: underline;
	font-size: 14px;
}
.td-TB {
	td {
		vertical-align: middle;
		padding: 10px;
	}
}

.status-badge-paid {
	padding: 4px;
	text-align: center;
	border-radius: 4px;
	font-size: 14px;
	color: #019d4c;
	background: rgba(1, 157, 76, 0.16);
}
.status-badge-pending {
	padding: 4px;
	text-align: center;
	border-radius: 4px;
	font-size: 14px;
	color: #fb881e;
	background: rgba(251, 136, 30, 0.16);
}
.status-badge-canceled {
	padding: 4px;
	text-align: center;
	border-radius: 4px;
	font-size: 14px;
	color: #ff3131;
	background: rgba(255, 49, 49, 0.16);
}

.wa_count {
	border: 1px solid rgba(112, 113, 112, 0.1);
	border-radius: 5px;
	width: 100%;
	margin: 4px 0 !important;
	min-height: 44px;
}
.wa_title {
	background-color: #dddcdc;
	border-radius: 3px;
	border: 1px solid #dddcdc;
}
.wa-room {
	background-color: #f7f6f6;
	border-radius: 3px;
	border: 1px solid rgba(112, 113, 112, 0.1);
}
.left_row {
	margin: 4px 0 !important;
}
.wa_count2 {
	border: 1px solid rgba(112, 113, 112, 0.1);
	border-radius: 5px;
	width: 100%;
	min-height: 44px;
}
.gutters {
	.pl-3 {
		background: none !important;
	}
}
.wa-ghameq {
	background-color: #707170;
	border-radius: 3px;
	border: 1px solid #707170;
	color: #fff;
}
.wa-fire_drop {
	color: #d4a655;
	-webkit-margin-end: 5px;
	margin-inline-end: 5px;
	display: inline-block;
	text-decoration: underline;
}

@media screen and (min-width: 1024px) {
	.table-responsive {
		overflow-x: visible !important;
	}
}
.confirmation-modal {
	margin-top: 12%;
}

.mt-fixNav {
	margin-top: -60px !important;
}
.pt-30 {
	padding-top: 30px !important;
}
.borderRid {
	border-radius: 5px !important;
}

.text-filter {
	color: #0c3b5c !important;
	font-size: 16px !important;
}
.w-20 {
	width: 238px !important;
}

[dir="rtl"] .filter-check-box {
	padding-left: 1.7rem !important;
	padding-right: 10px !important;
	.custom-control-label::before {
		left: -1.2rem !important;
		right: unset !important;
	}

	.custom-control-label::after {
		left: -1.2rem !important;
		right: unset !important;
	}
}

.btn-outline-sbs {
	margin-top: -5px;
	border: 1px solid #cc923e;
	color: #cc923e;
	font-size: 16px;
	display: inline-block;
	align-items: center;
	align-content: center;
	padding: 12px 14px;
	img {
		margin-top: -2px;
	}
}
.switch-services-btn {
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	// gap: 8pxz;
	border-radius: 8px;
	border: 1px solid #f7f6f6;
	background-color: #fefeff;
	font-size: 16px;
	font-weight: 600;
	color: #050001;
	padding: 8px;
	width: 225px;
	.switch-services-icon {
		padding: 4px 8px;
		border-radius: 6px;
		background-color: #f7f6f6;
		margin: 0 6px;
	}
}
.umrah-trail-plan {
	position: relative;
	top: -3px;
	width: 380px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 4px 8px;
	background: linear-gradient(90deg, #edd7b1 0%, #fbfbfa 100%);
	border: 1px solid #d4a655;
	border-radius: 4px;
	.expire_at,
	.remaining_days {
		color: #707170;
		font-size: 12px;
		font-weight: 400;
		line-height: 12.5px;
	}
	.remaining_days {
		color: #705424;
		font-weight: 700;
	}
}

img.fix-flag-inner {
	width: 26px;
	left: 12px;
}
img.fix-flag-inner {
	[dir="rtl"] & {
		right: 12px !important;
		left: unset !important;
	}
}
.w-89 {
	width: 89% !important;
}
.booking-service-details {
	.mybooking-search-wrapper {
		.icon-services {
			.search {
				[dir="rtl"] & {
					right: unset !important;
					left: 10px !important;
					top: 11.5% !important;
				}
			}
		}
	}
}

.title-marketPlace {
	font-size: 30px;
	font-weight: 700;
	text-transform: capitalize;
}
@media (max-width: 992px) {
	.switch-services-btn,
	.umrah-trail-plan {
		display: none;
	}
}
.fixH {
	.control-field {
		height: auto !important;
	}
	div[class$="-control"] {
		max-height: initial !important;
	}

	div[class$="-ValueContainer"] {
		gap: 6px;
		font-size: 14px !important;
		max-height: initial !important;
	}
}

.newboxCancellation {
	width: 80%;
	margin: auto;
}

.fixFormAll {
	.control-field__body {
		width: min-content !important;
	}
}

.caption {
	display: block !important;
	* {
		top: -7px;
		right: -12px;
		background-color: #d4a655 !important;
	}
}

.react-datepicker-popper {
	z-index: 9999 !important;
}

.multi-range-slider .label {
	width: 100% !important;
}

.carousel-button-group-new {
	position: absolute;
	width: 100%;
	top: 43%;
	.react-multiple-carousel__arrow {
		background: #f7f6f6;
	}
	.react-multiple-carousel__arrow--right {
		right: 0 !important;
	}
	.react-multiple-carousel__arrow--left {
		left: 0 !important;
	}
	.react-multiple-carousel__arrow:before {
		color: #222;
	}
	.react-multiple-carousel__arrow:hover {
		background: #f7f6f6;
	}
	.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right.disable {
		display: none;
	}
	.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left.disable {
		display: none;
	}
}

.react-multi-carousel-list.new-market-packages-countries {
	width: 90%;
	margin: auto;
}
.market-place-packages {
	position: relative;
}

.pointer {
	cursor: pointer !important;
}

.OperationDetails {
	.label-pax {
		font-size: 14px !important;
		font-weight: 400 !important;
		letter-spacing: 0em !important;
		color: #707170 !important;
	}
	.pax-input {
		.input-container {
			margin-top: 2px;
		}
	}
	label {
		font-size: 14px !important;
		font-weight: 400 !important;
		letter-spacing: 0em !important;
		color: #707170 !important;
	}
	.fix_place_arrow {
		right: 7px;
	}

	.supervisor-phone, .supervisor-name {
		@media screen and (max-width: 767px) {
			margin-top: 1rem;
		}
	}
}

.box-hotel {
	width: 100%;
	background-color: #f7f6f6;
}

.city-title {
	font-size: 18px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0px;
	color: #d2ab67;
}
.wid-20 {
	width: 20% !important;
}
.wid-10 {
	width: 10% !important;
}
.wid-15 {
	width: 15% !important;
}
.hide-label-pax {
	.label-pax {
		display: none;
	}
}
.btn-Delete-operation {
	border: 0;
	background: none;
	font-family: Cairo;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0em;
	color: #ea5455;
	span {
		margin: 0 4px;
		width: 20px;
		height: 40px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
	}
}
.btn-plus-operation {
	border: 0;
	background: none;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0em;
	span {
		margin: 0 4px;
		background-color: #eed9ba;
		width: 40px;
		height: 40px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		color: #6a4d1b;
		border-radius: 6px;
	}
}

.operating-title-view {
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
	color: #050001;
}
.operating-view-container {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 16px 24px;
	border: 1px solid #f4f4f7;
	border-radius: 8px;
	.operation-tabs .item {
		font-size: 18px;
	}
}
.operating-btn-back {
	height: 40px;
	background-color: #219653;
	border: none;
	text-align: center;
	border-radius: 8px 0 0 8px;
	padding: 10px 16px 10px 16px;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0em;
	color: #ffffff;
	[dir="rtl"] & {
		border-radius: 0 8px 8px 0;
	}
}
.operating-btn-next {
	height: 40px;
	background-color: #219653;
	border: none;
	text-align: center;
	border-radius: 0 8px 8px 0;
	padding: 10px 16px 10px 16px;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0em;
	color: #ffffff;
	[dir="rtl"] & {
		border-radius: 8px 0 0 8px;
	}
}
.height-fill-available {
	height: 100% !important;
}
.height-center-available {
	text-align: center;
	align-content: center;
	flex-wrap: wrap;
	justify-content: center;
}
.disabled-li-Tabs {
	opacity: 0.5;
}
.phone_code-fix {
	z-index: unset !important;
	div[class$="-container"] {
		z-index: 9 !important;
	}
}

.btn-Product-Filter:disabled {
	opacity: 0.5;
}
.wrapper-operations {
	display: flex;
	flex-direction: column;
	grid-gap: 24px;
	gap: 24px;
	padding: 32px 26px;
	border-radius: 8px;
	border: 1px solid #f7f6f6;
}
.px-new-26 {
	padding-right: 26px;
	padding-left: 26px;

	@media screen and (max-width: 767px) {
		padding-right: 0;
		padding-left: 0;
	}
}
.umrah-operation-breadcrump {
	.newFixPadding {
		padding: 5px 10px !important;
	}
}
.text-end {
	text-align: end !important;
}
.text-start {
	text-align: start !important;
}
.align-item-center {
	align-items: center !important;
}

.DateRangePickerInput.DateRangePickerInput_1 {
	align-items: center !important;
}
.date-icon-range {
	position: absolute;
	left: unset;
	bottom: 3px;
	font-size: 16px;
	color: #acacac;
	font-weight: normal;
	z-index: 1;
	right: -12px;
	top: -12px;
}
.btn-plus-operation-sp::after {
	content: " | ";
	position: relative;
	left: 7px;
}
[dir="rtl"] {
	.btn-plus-operation-sp::after {
		left: unset;
		right: 7px;
	}
}
.data-label {
	font-size: 16px;
	font-weight: 400 !important;
	line-height: 16px;
	background-color: #f7f6f6 !important;
}
.text-center-input {
	input {
		text-align: center;
	}
}
.h-100 {
	height: 100% !important;
}
.react-datepicker__input-container {
	font-size: 15px !important;
}
.multi-select {
	.control-field__select div[class$="-multiValue"] {
		background-color: #f7f6f6;
		font-size: 14px;
		margin: 0 5px !important;
		div {
			font-size: 14px !important;
		}
	}
	.control-field__select [class$="-ValueContainer"] div {
		margin: 2px !important;
		border-radius: 4px;
		max-height: initial !important;
		align-items: center !important;
		font-size: 14px !important;
		color: #050001 !important;
	}
}

.type.active {
	background: #fdf4e3 !important;
	padding: 5px;
	border-radius: 16px;
	width: fit-content;
}

.type {
	padding: 5px;
	margin: 0 0.5rem;
}

@media (min-width: 320px) and (max-width: 600px) {
	.d-column-mob {
		flex-direction: column !important;
	}
	.services-list {
		display: flex !important;
		flex-wrap: nowrap !important;
		flex-direction: row !important;
		overflow: scroll !important;
		white-space: nowrap !important;
	}

	.search-services-wrapper .services-list .service-item {
		width: 100% !important;
		padding: 12px 5px !important;
	}
	.mo-w-100 {
		width: 100%;
	}

	.actions-mo {
		flex-wrap: wrap;
	}
	.special-allotment
		.special-data
		.item-data-container
		.item-data
		.data
		.actions
		button {
		order: 3;
		margin-top: 10px;
		width: 100%;
		border: 1px solid #1e85ff;
		padding: 0.5rem;
		text-decoration: none;
		text-align: justify;
		display: flex;
		justify-content: space-between;
	}

	.special-allotment
		.special-data
		.item-data-container
		.item-data
		.data
		.actions
		button
		svg {
		color: #1e85ff !important;
	}

	.special-allotment
		.special-data
		.item-data-container
		.item-data
		.data
		.actions
		.mob-hide
		svg {
		color: #98a2b3 !important;
	}

	.special-allotment
		.special-data
		.item-data-container
		.item-data
		.data
		.actions
		.price {
		width: 50%;
	}

	.special-allotment
		.allotment-details
		.tab-content
		.pricing
		.select-option
		.option-row {
		overflow: auto;
		white-space: nowrap;
	}

	.special-allotment
		.allotment-details
		.tab-content
		.pricing
		.select-option
		.option-row
		.item {
		padding: 0 10px;
	}

	.search-services-wrapper .service-form-item {
		background-color: rgba(0, 0, 0, 0.6) !important;
		margin: 0 !important;
		padding: 10px;
		border: none !important;
	}
	.search-services-wrapper .service-form-item .btn-yellow {
		width: 100% !important;
		padding: 1rem !important;
		margin: 0 !important;
		height: 48px;
		padding: 0px 32px;
		justify-content: center;
		align-items: center;
		gap: 8px;
	}
	.search-services-wrapper .service-form-item .form-item {
		border-right: none !important;
		// margin: 0.5rem !important;
	}

	.search-services-wrapper {
		padding: 0 !important;
	}
	.search-services-wrapper .services-list {
		border: none !important;
		background: #f7f6f6 !important;
		color: #050001 !important;
		border-radius: 0 !important;
		padding: 16px;
		align-items: center;
		gap: 10px;
		align-self: stretch;
	}
	.search-services-wrapper .services-list .active-item {
		border-radius: 60px !important;
		padding: 8px 16px !important;
		border: 1px solid #d4a655;
		color: #050001;
		font-weight: 400;
	}
	.search-services-wrapper .service-form-item .form-item {
		padding-block: 0 !important;
		margin: 0.3rem 0;
	}
	.m-m-b {
		margin: 1rem 0 !important;
		width: 100% !important;
	}

	.search-services-wrapper .service-form-item .form-item label {
		margin-bottom: 0.5rem !important;
	}
	.search-services-wrapper
		.service-form-item
		.form-item
		.control-field
		svg
		path {
		fill: #050001;
	}
	.search-services-wrapper .service-form-item .allotment-form {
		padding: 0.5rem !important;
	}
	.m-mob-auto {
		margin: auto !important;
	}
	.m-mob-auto img {
		width: 100% !important;
	}
	.p-mob {
		padding: 10px !important;
	}
	.search-services-wrapper .service-form-item .form-item .control-field {
		justify-content: space-between !important;
	}
	.no-gutters .control-field__select div {
		// margin-left: -8px!important;
		font-weight: bold;
	}

	.special-allotment
		.allotment-details
		.tab-content
		.pricing
		.select-option
		.head {
		background-color: #fff !important;
		padding: 14px;
		border-bottom: none !important;
		border-radius: 8px 8px 0 0px;
		color: #222222 !important;
	}
	.special-allotment .allotment-details .tab-content .pricing .select-option {
		border: none !important;
	}

	.special-allotment
		.special-data
		.item-data-container
		.item-data
		.data
		.actions
		.mob-hide {
		border: 1px solid #98a2b3 !important;
		color: #98a2b3 !important;
	}

	.special-allotment .allotment-details .nav-tabs {
		display: flex;
		flex-wrap: nowrap;
		white-space: nowrap;
		overflow: auto;
	}

	.remove-bg {
		background-color: initial !important;
	}

	.label-m-color {
		color: #fbfbfa !important;
		font-size: 14px;
		margin-bottom: 4px !important;
	}

	.p-m-p .innerPadding {
		padding: 15px 18px !important;
	}

	.type label {
		margin-bottom: 0;
		color: #fff;
		white-space: nowrap;
		font-size: 14px;
	}

	// .control-field__body {
	// 	display: flex;
	// 	align-items: center;
	// 	width: 100%;
	// }

	.box-types .type.active {
		background-color: inherit !important;
	}

	.type.active {
		background-color: inherit !important;
	}

	.global-visa-form .global-visa-form-item.country {
		background-color: inherit !important;
	}

	.global-visa-form .global-visa-form-item {
		margin-block: 0 !important;
	}

	.type .active {
		background-color: inherit !important;
	}
}

.box-input-file {
	width: 100%;
	padding: 0 6px;
	border-radius: 6px;
	height: 42px;
	border: 1px dashed #60a5fa;
	background: #eff6ff;
	margin-top: 28px;
	p {
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
	}
	input[type="file"] {
		position: absolute;
		right: 0;
		opacity: 0;
		z-index: 2;
	}
}
.col-md15 {
	flex: 0 0 11.333333%;
	max-width: 11.333333%;
}
.bg-movement-color {
	.no-gutters {
		background-color: #f7f6f6 !important;
	}
	.newFixPadding {
		background-color: #f7f6f6 !important;
	}
	input {
		background-color: #f7f6f6 !important;
	}
}

.m-m-b button {
	width: 100% !important;
}

.search-services-wrapper .service-form-item .form-item {
	padding-block: 0 !important;
}

.special-allotment .allotment-details .nav-tabs .nav-item .nav-link {
	font-size: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.special-allotment .allotment-details .nav-tabs .nav-item .nav-link svg {
	margin-right: 5px;
}

.payment-title {
	.modal-title {
		width: 100%;
	}
}

.boxs-payment .item-payment .raido-label label {
	font-weight: 600 !important;
	color: #050001;
}
.boxs-payment .item-payment .raido-label input {
	width: 18px !important;
	height: 18px !important;
	margin-inline-end: 8px;
}
.title-pay {
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px;
	color: #050001;
}

.sub-title-pay {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	color: #707170;
}

.payment-text-price {
	font-size: 34px;
	font-style: normal;
	font-weight: 600 !important;
	color: #050001;
	span {
		font-size: 24px;
		font-style: normal;
		font-weight: 400 !important;
	}
}

.title-pay-choose {
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	color: #707170;
}
.fake-select .amount {
	border-radius: 4px !important;
	background: rgba(212, 166, 85, 0.2) !important;
	color: #d4a655 !important;
	padding: 0px 10px !important;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	display: flex;
	align-items: center;
}
.mx-md-25 {
	margin-left: 25px !important;
	margin-right: 25px !important;
}
.Deduct-title {
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	color: #d4a655;
}
.your-Balance {
	color: #707170;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	span {
		font-weight: 700;
	}
}

.LandbotLivechat {
	left: -15px !important;
}

[dir="rtl"] .LandbotLivechat {
	right: -15px !important;
	left: initial;
}

.type {
	// display: flex;
	// align-items: center;
	// justify-content: center;
}

.type input[type="radio"] {
	margin-right: 5px;
}

.type label {
	margin-bottom: 0;
}

.fix_place_arrow {
	position: absolute;
	top: 12px !important;
	right: 34px;
}

.fix_place_arrow i {
	color: #cb9a51;
}

.date-icon-range {
	[dir="rtl"] & {
		right: unset !important;
		left: -12px !important;
	}
}
.main-label {
	.date-icon-range {
		[dir="rtl"] & {
			right: unset !important;
			padding-top: 10px !important;
			margin-top: -4px;
			left: -15px !important;
		}
	}
}

.w-90 {
	width: 90% !important;
}
.w-85 {
	width: 85% !important;
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
}
.animate_New_fadeIn {
	animation: fadeIn 0.5s 1 alternate;
	animation-iteration-count: 1;
}

.btn-preview {
	background-color: var(--primary-color) !important;
	color: white !important;
	border: none !important;
	a {
		color: white !important;
	}
	svg {
		path {
			stroke: white !important;
		}
	}
}

.btn-preview:hover {
	color: white !important;
}

.header-webbuilder-btn {
	position: relative;
	.upgrade {
		position: absolute;
		top: -15px;
		inset-inline-end: -9px;
	}
}

@media (max-width: 1024px) and (min-width: 992px) {
	.bulid-pro-inputs {
		.col-md-2 {
			flex: 0 0 20.66666667%;
			max-width: 20.66666667%;
		}
		.col-md-1 {
			flex: 0 0 14.333333%;
			max-width: 14.333333%;
		}
	}
	.product-build__product-list .product-btn {
		transform: none !important;
	}
}
@media screen and (max-width: 1441px) and (min-width: 1025px) {
	.bulid-pro-inputs {
		.col-md-2 {
			flex: 0 0 20.66666667%;
			max-width: 20.66666667%;
		}
	}
	.product-build__product-list .product-btn {
		transform: none !important;
	}
}

.product-build__product-list .product-btn {
	transform: none !important;
}
@media screen and (max-width: 767px) {
	.hd-bulid-pro {
		height: auto;
	}
	.footer-container {
		flex-direction: column;
		gap: 12px;
		justify-content: center;
		align-items: center;

		.social-platforms {
			margin-inline-end: 0;
		}
	}
}

.table th,
.table td {
	vertical-align: middle !important;
}
.popover-body {
	width: 150px !important;
}
