.zayara-forum {
	--container-width: 1440px;

	text-align: start !important;
	direction: rtl !important;

	.container {
		position: relative;
		width: 100%;
		padding: 0 24px;
		max-width: var(--container-width);
		margin: 0 auto;

		@media screen and (max-width: 567px) {
			padding: 0 12px;
		}

		@media screen and (max-width: 1440px) {
			--container-width: 1360px;
		}

		@media screen and (max-width: 1280px) {
			--container-width: 1200px;
		}

		z-index: 9;
	}

	&.inner-page {
		.hero-section {
			height: 260px;
		}

		.header-title {
			position: relative;
			max-width: 600px;
			margin: 0 auto;
			text-align: center;
			font-weight: 700;
			font-size: clamp(18px, 2vw + 12px, 32px);
			line-height: 120%;
			z-index: 1;
		}

		.landing-groups-container {
			.container {
				position: relative;
				z-index: 3;
			}

			.group-title {
				text-align: start;

				&::after {
					inset-inline-start: 0;
					left: unset;
					transform: unset;
				}
			}

			.description {
				text-align: start;
				max-width: 90%;
				margin: 0;
				line-height: 27px;
			}
		}
	}

	&.form-page {
		.header-title {
			text-align: start;
			align-self: flex-start;
			margin: unset;
		}
		.form-container {
			padding-inline: 80px;
		}
	}

	.hero-section {
		position: relative;
		height: 644px;
		overflow: hidden;

		.hero-banner {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: #eee9dde5;
			}
		}


		.logo {
			position: relative;
			top: 2rem;
		}

		.content-section {
			/* display: grid;
			grid-template-columns: 1fr 1fr; */
			justify-content: center;
			/* gap: 24px; */

			h1 {
				font-weight: 700;
				font-size: clamp(18px, 2vw + 12px, 32px);
				line-height: 110%;
				letter-spacing: 0;
				text-align: right;
				vertical-align: middle;
				margin-top: -1rem;
			}

			p {
				font-weight: 400;
				font-size: clamp(14px, 1vw + 12px, 26px);
				line-height: 39px;
				letter-spacing: 0;
				margin-top: 2rem;
				text-align: right;
				vertical-align: middle;
			}

			.hero-image {
				position: relative;
				top: -2.4rem;
				max-height: 600px;
			}
		}
	}

	/* ***************************************** landig group sections ************************************** */
	.landing-groups-container {
		position: relative;
		padding: 70px 80px;

		&::before,
		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			max-width: 100%;
			width: 360px;
			height: 100%;
			max-height: 500px;
			background-image: url("../../modules/UmrahForum/assets/zayara-forum-bg.png");
			background-repeat: no-repeat;
			background-size: contain;
			z-index: 1;
		}

		&::after {
			transform: scale(-1, 1);
			left: unset;
			right: 0;
		}

		.title {
			position: relative;
			font-weight: 700;
			font-size: clamp(18px, 2vw + 12px, 32px);
			line-height: 39px;
			margin-bottom: 2rem;
			padding-bottom: 1.6rem;
			text-align: center;

			&::after {
				content: "";
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: 0;
				width: 60px;
				height: 5px;
				background-color: #d59f4c;
			}
		}

		.description {
			max-width: 800px;
			margin: 0 auto;
			font-weight: 400;
			font-size: clamp(14px, 0.75vw + 12px, 18px);
			line-height: 27px;
			text-align: center;
		}
	}

	.groups-list {
		position: relative;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;
		z-index: 1;

		.item {
			--top_box_size: 80px;
			position: relative;
			padding: 110px 30px 10px 30px;
			border-radius: 20px;
			border: 1px solid #dddcdc;
			background-color: #ffffff;
			text-align: center;
			margin-top: calc(var(--top_box_size) / 2);

			.icon {
				position: absolute;
				display: flex;
				justify-content: center;
				align-items: center;
				width: var(--top_box_size);
				top: calc(var(--top_box_size) / 3 * -1);
				left: 50%;
				transform: translateX(-50%);
				border-radius: 20px;
				border: 1px solid #dcdcdc;
				background-color: #ffffff;
				aspect-ratio: 1;
			}

			.group-title {
				max-width: 80%;
				margin: 0 auto;
				text-align: center;
				font-weight: 500;
				font-size: 18px;
				line-height: 120%;
				text-align: center;
			}

			a {
				color: #d59f4c;
			}
		}
	}

	.footer-links {
		position: relative !important;
		padding: 40px;
		border-radius: 40px;
		overflow: hidden;

		p {
			position: relative;
			font-size: clamp(14px, 1vw + 6px, 26px);
			font-weight: 500;
			max-width: 700px;
			margin: 0 auto;
			text-align: center;
			z-index: 1;
		}

		&::before,
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			inset: 0;
			z-index: 0;
		}

		&::before {
			opacity: 0.2;
			mix-blend-mode: Luminosity;
			background-image: url("../../modules/UmrahForum/assets/footer-bg.png");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 0 75%;
			z-index: 1;
		}

		&::after {
			background: linear-gradient(180deg, #faf4eb 3.47%, #faf4eb 97.11%);
			z-index: 0;
		}
	}

	.page-footer {
		background-color: #050001;
	}

	.control-field__label,
	.control-field__feedback {
		text-align: start !important;
		font-size: 14px !important;
	}

	.phone_code {
		inset-inline-end: 5px;
		top: 24px;
		text-align: start !important;
		width: 100px;

		.control-field {
			margin-block: 6px;
			border-inline-start: 1px solid #ebebeb;
		}

		div[class$="-placeholder"] {
			color: #cccccc;
			opacity: 0.7;
		}
	}

	.main-btn {
		position: relative;
		background-color: #d59f4c;
		font-weight: 500;
		font-size: clamp(14px, 0.5vw + 12px, 18px);
		text-wrap: nowrap;
		padding-inline: 2rem;
		text-align: center;
		color: #ffffff;
		border-radius: 8px;
		z-index: 2;
		overflow: hidden;
	}

	.form-btn {
		padding-block: 8px;
		border: 1px solid #259A62;
		background-color: #259A62;
		color: #F2F3F3;
	}

	@media (max-width: 1024px) {
		.groups-list {
			grid-template-columns: repeat(2, 1fr) !important;
		}
	}

	@media screen and (max-width: 767px) {
		.hero-section {
			height: 80vh;


			.container {
				padding-top: 1rem;
			}

			.logo {
				top: 0;
			}

			.content-section {
				flex-direction: column-reverse;
				gap: 24px;
				margin-top: 1rem;

				h1 {
					margin-top: 0;
					line-height: 90%;
				}

				p {
					line-height: 150%;
					margin-top: 1.4rem;
				}

				.hero-image {
					top: 0;
					min-width: 300px;
					max-width: 60vw;
					margin: .5rem auto;
				}
			}
		}

		.groups-list {
			grid-template-columns: repeat(1, 1fr) !important;
		}

		.landing-groups-container {
			padding: 1rem 0;

			.title {
				padding-bottom: 0.5rem;
				margin-bottom: 0.9rem;
			}
		}

		&.inner-page {
			.hero-section {
				height: 160px;
			}
			.header-title {
				text-align: start;
				margin-top: .8rem;
			}

			.title {
				padding-bottom: 0.5rem;
				margin-bottom: 0.9rem;
				margin-top: 1rem;
				text-align: start;

				&::after {
					inset-inline-start: 0;
					left: unset;
					transform: unset;
				}
			}
		}
		&.form-page {
			.header-title {
				text-align: start;
				align-self: flex-start;
				margin: unset;
			}
			.form-container {
				padding-inline: 24px;
			}
		}
	}
}